.wrapperClassName {
  display: flex;
  flex-direction: column-reverse;
  color: gray;
}

.editorClassName {
  background-color: white;
  margin-bottom: 5px;
  border: 4px rgb(152, 151, 151);
  cursor: text;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /* Apply a box shadow */
  border: 1px solid #cacaca;
  padding-left: 5px;
  border-radius: 5px;
}
#font-picker-1 {
  .dropdown-button {
    background-color: #ffffff !important;
    border: 1px solid rgb(189, 183, 183);
    border-radius: 5px;
  }
  .font-button.active-font {
    background-color: #ffffff !important;
  }
  .font-list-item {
    background-color: #ffffff !important;
  }
}
