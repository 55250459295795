.StripeElement {
  margin-top: 20px;
  padding: 10px 12px;
  color: #32325d;
  /* background-color: white; */
  border: 1px solid transparent;
  border-radius: 2px;
}

.btn-pay {
  background: primary-color;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  align-self: center;
  left: auto;
  right: auto;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 80%;
}

#address {
  width: 70%;
  height: auto;
}

#form {
  margin-top: 70px;
}
